export const personalInformation = [
  `Request information from Star Health's Web site.`,
  `Use online tools and calculators.`,
  `Apply online for products.`,
  `Subscribe to online services.`,
  `Complete an online form.`,
  `Conduct transactions online.`,
  `Apply for a job online.`,
];

export const usefulInformation = [
  `Allow you to access specific account information`,
  `Providing customization: We may use the information provided by you to customize your visit to the Web Site by displaying appropriate content at our discretion.`,
  `To send you information about products and services offered by Star Health. If you do not wish to receive such information, you may unsubscribe to the facility in the email message received by you.`,
  `To contact you for policy reminder notices, claims processing and to keep you updated on the insurance sector and Star Health through our newsletters.`,
];

export const disclosureInfo = [
  `Conform to any statutory or legal requirements or comply with legal process`,
  `Protect, enforce and defend our rights or property`,
  `Protect our interests`,
];
