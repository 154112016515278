import { whiteColor, blackColor } from "assets/jss/material-kit-pro-react.jsx";

export default function() {
  return {
    container: {
      color: blackColor,
      padding: `10px`,
      backgroundColor: whiteColor,
      "& h2": {
        fontSize: `1.5em`,
        fontWeight: `bold`,
      },
    },
  };
}
