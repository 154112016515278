import React from "react";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import StaticPrivacyStyles from "ps-assets/jss/StaticPrivacyStyles";
import {
  personalInformation,
  usefulInformation,
  disclosureInfo,
} from "ps-assets/constants/StaticPrivacy";

function getLists(arr) {
  return (
    <ul>
      {arr.map((info, index) => (
        <li key={index}>
          <p>{info}</p>
        </li>
      ))}
    </ul>
  );
}

function StaticPrivacy({ classes }) {
  return (
    <InstantBuyLayout>
      <div className={classes.container}>
        <h2>Privacy Policy</h2>
        <p>
          We value you as a customer and respect your right to privacy. In the
          process of operating the Web Site, we may become aware of information
          relating to you, including information that is of a confidential
          nature. We are strongly committed to protecting your privacy online
          and have taken steps to protect such information. To aid us in
          protecting your privacy, you should maintain secrecy of your login ID
          and password, if any, provided to you in connection with your account
          with www.starhealth.in
        </p>
        <h2>Collecting your personal information</h2>
        <p>Star Health may collect the information you provide when you:</p>
        {getLists(personalInformation)}
        <h2>Use of your personal information</h2>
        <p>
          By using this website you authorize us to contact you via email or
          phone on your contact details provided above and offer you information
          and services for the product you have opted for. You authorize us to
          call you for the mentioned purpose for a period of upto 30 days
          irrespective of whether you are registered with the NDNC registry. We
          will use the information primarily for the following purposes:
        </p>
        {getLists(usefulInformation)}
        <h2>Log Files</h2>
        <p>
          As is true of most Web sites, we gather certain information
          automatically and store it in log files. This information includes
          internet protocol (IP) addresses, browser type, internet service
          provider (ISP), referring/exit pages, operating system, date/time
          stamp, and clickstream data.
        </p>
        <p>
          We use this information, which does not identify individual users, to
          analyze trends, to administer the site, to track users’ movements
          around the site and to gather demographic information about our user
          base as a whole.
        </p>
        <p>
          We do not link this automatically collected data to personally
          identifiable information. IP addresses are tied to personally
          identifiable information to help us customize your visit to our
          website so that you like the experience. However, we would like to
          reiterate that this information is not shared with third parties,
          except as provided under “Disclosure of Information” clause.
        </p>
        <p>Use of Cookies</p>
        <p>
          We store a cookie on your computer when you visit our Web site. A
          cookie is a tiny piece of information that is required to create and
          maintain a secure session. Our cookies let you view customized pages
          while transacting with us. Our cookies do not have confidential or
          personal information. Since, we use session ID cookies, they do not
          track a user after leaving our Web site. A session ID cookie expires
          when you close the browser. It is required if you want to buy a policy
          online from us.
        </p>
        <p>
          The primary purpose of these cookies is to analyze how users move
          within our Web site.
        </p>
        <h2>Disclosure of Information</h2>
        <p>
          Subject to the other terms contained herein, , we will not disclose
          your information to any person, unless acting under a good faith
          belief that such action is necessary to:
        </p>
        {getLists(disclosureInfo)}
        <p>
          We may also use your personal information for the purposes of
          providing you with any services and service-related activities. In
          this regard, it may be necessary to disclose your personal information
          to one or more service providers of Star Health providing services
          linked to Insurance sector.
        </p>
        <p>
          We reserve the right to exchange, share or provide the information
          relating to your personal and financial details and information with
          other Statutory Bodies as required and you will not hold us liable for
          use or sharing of the information.
        </p>
        <h2>Updating your personal information</h2>
        <p>
          If you need to update your personal information or there is any change
          in the personal information, or if you no longer desire our service,
          you may update or delete it by making the change on our registration
          page or by emailing our Website team at retail@starhealth.in or by
          contacting us by telephone or postal mail at the contact information
          listed below.
        </p>
        <h2>Security</h2>
        <p>
          The security of your personal information is important to us. We
          follow generally accepted industry standards to protect the personal
          information submitted to us, both during transmission and once we
          receive it. No method of transmission over the Internet, or method of
          electronic storage, is 100% secure, however. Therefore, while we
          strive to use commercially acceptable means to protect your personal
          information, we do not guarantee its absolute security.
        </p>
        <h2>Links to other sites</h2>
        <p>
          The Web Site may contain links to other sites and/or portals on the
          Internet. While we intend to link only to such sites that share our
          high standards and respect for privacy, we are not responsible for the
          content or the privacy practices employed by such other sites.
        </p>
        <p>
          Please be aware that websites that have links from our site may
          collect personally identifiable information about you. This privacy
          statement does not cover the information and disclosure practices of
          those web sites.
        </p>
        <h2>Changes in this Privacy Statement</h2>
        <p>
          We reserve the right to modify this privacy statement at any time by
          posting the same on this Website, so please review it frequently on
          the Website. If we materially change our privacy practices we will
          notify you by sending an email or by posting a notice on our web site.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or suggestions regarding our privacy policy,
          please contact us at:
        </p>
        <p>
          The Website Team
          <br />
          Star Health and Allied Insurance Co Ltd.,
          <br />
          Chennai-600 034.
        </p>
        <p>
        Sales & Services - 044 4674 5800
          <br />
          Tel No. : +91 44 28288800
          <br />
          Email : website@starhealth.in
        </p>
      </div>
    </InstantBuyLayout>
  );
}

getLists.propTypes = {
  arr: PropTypes.array,
};

StaticPrivacy.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(StaticPrivacyStyles)(StaticPrivacy);
